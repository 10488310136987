<template>
  <div class="current-user-profile">
    <div class="workspace-column w-100">
      <div class="panel panel-full w-100 h-100">
        <div class="panel-content" v-show="username != ''">
          <div class="p-5 text-center">
            <b-avatar size="8rem" variant="secondary" class="mb-2"></b-avatar>
            <h1>{{ name }}</h1>
            <h5>
              <b-badge class="ml-1" pill :variant="userIsActiveClass">{{
                userIsActiveText
              }}</b-badge>
              <b-badge
                class="ml-1"
                pill
                :variant="userIsSuperuserClass"
                v-show="userIsSuperuserClass != ''"
              >
                {{ userIsSuperuserText }}
              </b-badge>
            </h5>
          </div>

          <div class="m-2 p-4">
            <b-card-group deck>
              <b-card
                title="Personal Info"
                sub-title="Basic info, like your name and username, that you use on Smart Canopy"
                class="bg-surface-1"
              >
                <b-card-text>
                  <div class="pt-3">
                    <div>Username</div>
                    <div>
                      <strong>{{ username }}</strong>
                    </div>
                  </div>

                  <div class="pt-3">
                    <div>Name</div>
                    <div>
                      <strong>{{ name }}</strong>
                    </div>
                  </div>
                </b-card-text>

                <template #footer>
                  <b-button
                    variant="primary"
                    class="card-link"
                    v-b-modal.form-update
                    v-b-tooltip.hover
                    title="Update"
                  >
                    <i class="fa fa-edit"></i>
                    Update
                  </b-button>
                </template>
              </b-card>

              <b-card
                title="Security"
                sub-title="Settings and recommendations to help you keep your account secure"
                class="bg-surface-1"
              >
                <b-card-text>
                  <div class="pt-3">
                    <div>Password</div>
                    <div><strong>**********</strong></div>
                    <!--                     <div><small>Last changed {{ updated }}</small></div> -->
                  </div>
                </b-card-text>

                <template #footer>
                  <b-button
                    variant="primary"
                    class="card-link"
                    v-b-modal.form-update-password
                    v-b-tooltip.hover
                    title="Update Password"
                  >
                    <i class="fa fa-edit"></i>
                    Update
                  </b-button>
                </template>
              </b-card>
            </b-card-group>
          </div>
        </div>

        <!--  modal-->

        <b-modal
          title="Update User"
          id="form-update"
          ref="formUpdate"
          centered
          hide-footer
        >
          <b-form-group
            id="input-group-username"
            label="Username:"
            label-for="input-username"
          >
            <b-form-input
              id="input-username"
              v-model="form.username"
              type="text"
              placeholder="Enter your username"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-name"
            label="Name:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="form.name"
              type="text"
              placeholder="Enter your name"
              required
            ></b-form-input>
          </b-form-group>

          <div
            class="text-danger mb-2"
            v-show="changePasswordErrorMessage != ''"
          >
            {{ changeInfoErrorMessage }}
          </div>

          <b-button variant="primary" @click="confirmChangeInfo"
            >Submit</b-button
          >
        </b-modal>

        <b-modal
          title="Update User Password"
          id="form-update-password"
          ref="formUpdatePassword"
          centered
          hide-footer
        >
          <b-form>
            <b-form-group
              id="input-group-old-password"
              label="Current password:"
              label-for="input-old-password"
            >
              <b-form-input
                id="input-old-password"
                v-model="form.old_password"
                type="password"
                placeholder="Enter your current password"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-new-password"
              label="New password:"
              label-for="input-new-password"
            >
              <b-form-input
                id="input-new-password"
                v-model="form.new_password"
                type="password"
                placeholder="Enter your new password"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-confirm-password"
              label="Confirm password:"
              label-for="input-confirm-password"
            >
              <b-form-input
                id="input-confirm-password"
                v-model="form.confirm_password"
                type="password"
                placeholder="Confirm your new password"
                required
              ></b-form-input>
            </b-form-group>

            <div
              class="text-danger mb-2"
              v-show="changePasswordErrorMessage != ''"
            >
              {{ changePasswordErrorMessage }}
            </div>

            <b-button variant="primary" @click="confirmChangePassword"
              >Submit</b-button
            >
          </b-form>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";

let utils = require("@/assets/global/js/utils.js");

export default {
  data() {
    return {
      userID: "",
      username: "",
      name: "",
      userIsActiveClass: "",
      userIsActiveText: "",
      userIsSuperuserClass: "",
      userIsSuperuserText: "",
      created: "",
      updated: "",
      is_superuser: false,
      is_admin: false,
      showChangeInfo: false,
      changeInfoErrorMessage: "",
      changePasswordErrorMessage: "",
      form: {
        old_password: "",
        new_password: "",
        confirm_password: "",
        name: "",
        username: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  mounted: function () {
    this.getUserData(this.$store.getters["session/getCurrentUser"]);
  },
  methods: {
    getUserData: function (current_user) {
      this.userID = current_user.id;
      this.username = current_user.username;
      this.name = current_user.name;

      this.form.username = this.username;
      this.form.name = this.name;

      this.is_superuser = current_user.is_superuser;
      this.is_admin = current_user.is_admin;
      this.created = utils.formatTimestamp(current_user.created);
      this.updated = utils.formatTimestamp(current_user.modified);

      if (current_user.is_active) {
        this.userIsActiveClass = "success";
        this.userIsActiveText = "Active";
      } else {
        this.userIsActiveClass = "secondary";
        this.userIsActiveText = "Inactive";
      }

      if (current_user.is_superuser) {
        this.userIsSuperuserClass = "warning";
        this.userIsSuperuserText = "Superuser";
      }
      if (current_user.is_admin) {
        this.userIsSuperuserClass = "info";
        this.userIsSuperuserText = "Admin";
      }
      // console.log(current_user);
    },
    confirmChangePassword: function () {
      var $this = this;
      $this.$bvModal
        .msgBoxConfirm(
          "Confirm changing your password? You will be forced logout and please login with new password.",
          {
            centered: true
          }
        )
        .then((value) => {
          if (value == true) {
            // this.$refs.formUpdatePassword.hide();
            $this.doChangePassword();
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    doChangePassword: function () {
      var $this = this;
      var post_data = {
        old_password: $this.form.old_password,
        new_password_1: $this.form.new_password,
        new_password_2: $this.form.confirm_password
      };

      var API_URL =
        $this.getAPIServerURL + "/users/" + $this.userID + "/password/change/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, post_data)
        .then((data) => {
          if (data == null) {
            $this.form.old_password = "";
            $this.form.new_password = "";
            $this.form.confirm_password = "";

            $this.changePasswordErrorMessage = "";
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Changed password successfully.",
              message_type: "success"
            });

            $this.$refs.formUpdatePassword.hide();

            setTimeout(() => {
              $this.$store.dispatch("session/logoutSession");
            }, 1000);
          } else {
            if (data.detail != undefined) {
              $this.changePasswordErrorMessage = data.detail;
            } else {
              $this.changePasswordErrorMessage =
                "There seems to be a problem with this operation";
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    confirmChangeInfo: function () {
      var $this = this;
      $this.$bvModal
        .msgBoxConfirm("Confirm changing your information?", {
          centered: true
        })
        .then((value) => {
          if (value == true) {
            $this.doChangeInfo();
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    doChangeInfo: function () {
      var $this = this;
      var post_data = {
        username: $this.form.username,
        name: $this.form.name
      };

      var API_URL = $this.getAPIServerURL + "/users/" + $this.userID + "/";
      const client = $this.$root.getAjaxFetchClient();

      client
        .putRequest(API_URL, post_data)
        .then((data) => {
          if (data == null) {
            var current_user = $this.$store.getters["session/getCurrentUser"];
            current_user["username"] = $this.form.username;
            current_user["name"] = $this.form.name;
            $this.$store.dispatch("session/setCurrentUser", current_user);

            $this.showChangeInfo = false;
            $this.changeInfoErrorMessage = "";
            this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Changed user information successfully.",
              message_type: "success"
            });
            this.$refs.formUpdate.hide();

            location.reload();
          } else {
            if (data.detail != undefined) {
              $this.changeInfoErrorMessage = data.detail;
            } else {
              $this.changeInfoErrorMessage =
                "There seems to be a problem with this operation";
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    }
  }
};
</script>
